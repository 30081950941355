import React from "react";
import { Button } from "react-bootstrap";

const DeleteModal = ({ handleSubmit, handleCloseModal, modalName }) => {
  return (
    <>
      <div className="modal-header">
        <div className="modal-title h4">{`Delete ${modalName}?`}</div>
      </div>
      <div className="text-center modal-body">Are you confirm?</div>

      <div className="modal-footer">
        <Button
          className="save-btn"
          onClick={handleCloseModal}
          variant="secondary"
        >
          No
        </Button>
        <Button className="close-btn" variant="primary" onClick={handleSubmit}>
          Yes
        </Button>
      </div>
    </>
  );
};

export default DeleteModal;
