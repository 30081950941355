import React from "react";
import BlogList from "../blogList/BlogList";

const DraftBlogList = () => {
  return (
    <>
      <BlogList apiUrl="/blogs" pageTitle="Draft Blogs" showPublishBtn="true" />
    </>
  );
};

export default DraftBlogList;
