class Schema {
  constructor() {
    this.rules = [];
  }

  required(message = "This field is required") {
    this.rules.push({
      validate: (value) =>
        value !== undefined && value !== null && value !== "",
      message,
    });
    return this;
  }

  minLength(min, message = `Minimum length is ${min}`) {
    this.rules.push({
      validate: (value) => typeof value === "string" && value.length >= min,
      message,
    });
    return this;
  }

  maxLength(max, message = `Maximum length is ${max}`) {
    this.rules.push({
      validate: (value) => typeof value === "string" && value.length <= max,
      message,
    });
    return this;
  }

  email(message = "Invalid email format") {
    this.rules.push({
      validate: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
      message,
    });
    return this;
  }

  url(message = "Invalid URL format") {
    this.rules.push({
      validate: (value) =>
        /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]+(\/[\w\d-._~:?#[\]@!$&'()*+,;=]*)?(\?[\w\d=&%]+)?$/.test(
          value
        ),
      message,
    });
    return this;
  }

  onlyString(message = "Only letters are allowed") {
    this.rules.push({
      validate: (value) => /^[A-Za-z\s]+$/.test(value),
      message,
    });
    return this;
  }

  test(customValidator, message = "Invalid value") {
    this.rules.push({
      validate: customValidator,
      message,
    });
    return this;
  }

  validate(value) {
    for (const rule of this.rules) {
      if (!rule.validate(value)) {
        return rule.message;
      }
    }
    return null;
  }
}

const validateForm = (schema) => (values) => {
  const errors = {};
  Object.keys(schema).forEach((field) => {
    const error = schema[field].validate(values[field]);
    if (error) {
      errors[field] = error;
    }
  });
  return errors;
};

export { Schema, validateForm };
