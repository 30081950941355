import React, { useCallback, useEffect, useState } from "react";

import "./blog.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";
import Banner from "./Banner/Banner";
import Card from "./card/Card";
import { apiCall } from "../../../API/apiCall";
import { toast } from "react-toastify";
import useDebounce from "../../../helper/debounce";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

const MemberTyp = localStorage.getItem("MemberType");

const Blog = () => {
  const [isLoader, setisLoader] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [latestFiveBlogs, setlatestFiveBlogs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("currentPage") || 1;

  const [paginationData, setPaginationData] = useState({
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    previousPage: null,
    nextPage: null,
  });

  const [search, setSearch] = useState("");

  const debounceValue = useDebounce(search.trim(), 1000);

  // useEffect(() => {
  //   setisLoader(true);
  //   fetchData(Number(currentPage), 9);
  // }, [currentPage, debounceValue]);

  // useEffect(() => {
  //   setisLoader(true);
  //   const fetchLatestFiveBlogs = async () => {
  //     try {
  //       const response = await apiCall({
  //         url: "/blogs/",
  //         method: "GET",
  //         params: { page: 1, limit: 5, isPublish: true },
  //       });
  //       setlatestFiveBlogs(response?.data);
  //       setisLoader(false);
  //     } catch (error) {
  //       // toast.error(error?.response?.data?.message || error.message);
  //       setisLoader(false);
  //     }
  //   };
  //   fetchLatestFiveBlogs();
  // }, []);

  // const fetchData = async (page, limit) => {
  //   try {
  //     const response = await apiCall({
  //       url: "/blogs/",
  //       method: "GET",
  //       params: {
  //         page,
  //         limit,
  //         searchValue: debounceValue || undefined,
  //         searchFields: "title",
  //         isPublish: true,
  //       },
  //     });

  //     setBlogs(response?.data);
  //     setPaginationData(response?.pagination);

  //     setisLoader(false);
  //   } catch (error) {
  //     // toast.error(error?.response?.data?.message || error.message);
  //     setisLoader(false);
  //   }
  // };

  const fetchData = useCallback(
    async (
      page,
      limit,
      debounceValue,
      setBlogs,
      setPaginationData,
      setisLoader
    ) => {
      try {
        const response = await apiCall({
          url: "/blogs/",
          method: "GET",
          params: {
            page,
            limit,
            searchValue: debounceValue || undefined, // Avoid sending empty search
            searchFields: "title",
            isPublish: true,
          },
        });

        setBlogs(response?.data);
        setPaginationData(response?.pagination);
      } catch (error) {
        // toast.error(error?.response?.data?.message || error.message);
      } finally {
        setisLoader(false);
      }
    },
    []
  );

  useEffect(() => {
    setisLoader(true);
    if (debounceValue !== undefined) {
      fetchData(
        Number(currentPage),
        9,
        debounceValue,
        setBlogs,
        setPaginationData,
        setisLoader
      );
    }
  }, [currentPage, debounceValue, fetchData]);

  useEffect(() => {
    setisLoader(true);
    const fetchLatestFiveBlogs = async () => {
      try {
        const response = await apiCall({
          url: "/blogs/",
          method: "GET",
          params: { page: 1, limit: 5, isPublish: true },
        });
        setlatestFiveBlogs(response?.data);
      } catch (error) {
        // toast.error(error?.response?.data?.message || error.message);
      } finally {
        setisLoader(false);
      }
    };
    fetchLatestFiveBlogs();
  }, []);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePageClick = (event) => {
    console.log("event.selected", Number(event.selected) + 1);

    setSearchParams((prev) => {
      prev.set("currentPage", Number(event.selected) + 1);
      return prev;
    });
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />

      {/* {isLoader && <Loader />} */}

      <div className="blog-container ">
        {/* Banner Component */}
        <Banner blogs={latestFiveBlogs} />
        <div className="container">
          {/* Search Box and Blogs Heading */}
          <div className="blog-head">
            <h3>Blog</h3>
            <div className="blog-input">
              <input
                disabled={isLoader}
                value={search}
                type="search"
                onChange={handleInputChange}
                placeholder="Search"
              />
            </div>
          </div>

          {/* Repeating Cards */}
          <div className="blog-card-list">
            {isLoader ? <Loader /> : <Card blogs={blogs} isLoader={isLoader} />}
          </div>

          <div className="pagin pagination-blog mb-3">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={
                isNaN(paginationData.totalPages) ? 1 : paginationData.totalPages
              }
              // initialPage={Number(currentPage - 1)}
              forcePage={Number(paginationData.currentPage - 1)}
              renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
