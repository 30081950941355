import React, { useEffect, useState } from "react";

import BlogForm from "../blogForm/BlogForm";

const EditBlog = () => {
  return (
    <>
      <BlogForm pageTitle="Edit Blog" />
    </>
  );
};

export default EditBlog;
