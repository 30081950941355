import React from "react";
import AdminNav from "./AdminNav";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    <>
      <AdminNav />
      <Outlet />
      <Footer />
    </>
  );
};

export default AdminLayout;
