import React, { useEffect, useRef, useState } from "react";
import { BASEURL } from "../../constant/constant";

const ImageUploader = ({ onFileChange, name, value, imgStyle }) => {
  console.log("Preview called", value);
  const fileInputRef = useRef();
  const [disableRemoveBtn, setDisableRemoveBtn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    value && `${BASEURL}/${value}`
  );

  const [imageUploaded, setImageUploaded] = useState({
    authorImage: false,
    coverImage: false,
  });

  useEffect(() => {
    if (typeof value === "string") {
      setSelectedImage(`${BASEURL}/${value}`);
      setDisableRemoveBtn(true);
    } else if (!value) {
      setSelectedImage(null);
      setDisableRemoveBtn(true);
    }
  }, [value]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setImageUploaded((prev) => ({
        ...prev,
        [name]: true,
      }));
      reader.onload = () => {
        setSelectedImage(reader.result);
        setDisableRemoveBtn(false);
        onFileChange && onFileChange(name, e.target.files[0]);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
      onFileChange && onFileChange(name, null, "deleteImage");
    }
  };

  const handleRemove = () => {
    console.log("Ali : ", imageUploaded);
    setImageUploaded((prev) => ({
      ...prev,
      [name]: false,
    }));
    console.log("Value : ", typeof value);
    console.log("Value :2 ", value);

    setSelectedImage(value && `${BASEURL}/${value}`);
    setDisableRemoveBtn(true);
    fileInputRef.current.value = "";
    onFileChange && onFileChange(name, null, "deleteImage");
  };

  console.log("Selected Image : ", selectedImage);
  console.log("Selected Image :22222222222222 ", imageUploaded);

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        onChange={handleFileChange}
        style={{
          display: imageUploaded[name] ? "none" : "block",
          padding: "10px",
        }}
      />

      {/* Display uploaded image */}
      {selectedImage && (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginTop: "10px",
            overflow: "hidden",
          }}
        >
          {/* Image preview  */}
          <img src={selectedImage} alt="Uploaded Preview" style={imgStyle} />

          {/* Remove button */}

          {!disableRemoveBtn && (
            <button
              onClick={handleRemove}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "red",
                color: "white",
                border: "none",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
