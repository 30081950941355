import React from "react";
// import BlogForm from "../blogForm/BlogForm";
import BlogForm from "../blogForm/BlogForm";

const AddBlog = () => {
  return (
    <div>
      <BlogForm pageTitle="Add Blog" />
    </div>
  );
};

export default AddBlog;
