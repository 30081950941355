import React, { useEffect, useState } from "react";

import "./faq.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";

const MemberTyp = localStorage.getItem("MemberType");

import faqimg from "../../../assets/images/faq_bg.svg";
import Grid from "@mui/material/Grid";

import { apiCall } from "../../../API/apiCall";
import { toast } from "react-toastify";
import FaqAccordion from "./FaqAccordian";

import { oopsText } from "../../../constant/constant";
import oops from "../../../assets/images/oops.png";

const Faq = () => {
  const [isLoader, setisLoader] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);

  const [expanded, setExpanded] = React.useState("");

  useEffect(() => {
    fetchData();
    setExpanded("");
  }, []);

  const fetchData = async () => {
    try {
      setisLoader(true);
      const response = await apiCall({
        url: "/faq/",
        method: "GET",
        params: { limit: false, isPublish: true },
        headers: {
          "x-api-token": process.env.REACT_APP_API_STATIC_TOKEN,
        },
      });

      if (response?.data?.length) {
        setBlogs(response?.data);
        const splitIndex = Math.ceil(response?.data?.length / 2);
        setFirstHalf(response?.data?.slice(0, splitIndex));
        setSecondHalf(response?.data?.slice(splitIndex));
      }

      setisLoader(false);
    } catch (error) {
      // toast.error(error?.response?.data?.message || error.message);
      setisLoader(false);
    }
  };

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="faqcontainer-outer">
        <div className="main-container faq_container">
          <div className="faq_banner container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <div className="faq_content">
                  <h2>Frequently Asked Questions</h2>
                  <p>
                    Live Ink® has conducted multiple scientific studies with
                    students of all ages and abilities, from 6 th grade through
                    medical school. The published papers, with links in the
                    Information section of the Readmax website, describe the
                    significant benefits of reading in Live Ink text format,
                    results that have been repeated in multiple settings, with
                    students from the US and other countries, and differing
                    demographic backgrounds.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={5} className="pading_unset">
                <div className="faq_img">
                  <img src={faqimg} alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {!isLoader && (
          <div className="faq_accordion custom-faq-accordion">
            <div className="container">
              {blogs.length ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FaqAccordion
                      faqs={firstHalf}
                      expanded={expanded}
                      handleExpansion={handleExpansion}
                      prefix="first"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FaqAccordion
                      faqs={secondHalf}
                      expanded={expanded}
                      handleExpansion={handleExpansion}
                      prefix="second"
                    />
                  </Grid>
                </Grid>
              ) : (
                <div className="oops-div d-flex justify-content-center align-items-center flex-column">
                  <img src={oops} />
                  <p>{oopsText}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default Faq;
