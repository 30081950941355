import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button, FormControlLabel, Switch, Tooltip } from "@mui/material";

import { BASEURL, HEAD_BG_COLOR } from "../../../../constant/constant";
import Loader from "../../../loader/Loader";
import { apiCall } from "../../../../API/apiCall";
import { toast } from "react-toastify";
import { BaseModal } from "../../../Modal/BaseModal";
import DeleteModal from "../../../common/DeleteModal";
import ReactPaginate from "react-paginate";
import FilterButtonGroup from "../../../common/FilterButtonGroup";
import "./bloglist.css";
import useDebounce from "../../../../helper/debounce";

const BlogList = ({
  apiUrl,
  // showDraftButton = false,
  pageTitle,
  showPublishBtn = false,
  showPublishStatus = false,
}) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [blogIdToDelete, setBlogIdToDelete] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("ALL");

  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search.trim(), 1000);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("currentPage") || 1;
  const pageLimit = 10;

  const [paginationData, setPaginationData] = useState({
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    previousPage: null,
    nextPage: null,
  });

  const navigate = useNavigate();

  const handleNavigation = ({ blog, pageName }) => {
    let url = "/admin/blogs";

    switch (pageName) {
      case "edit":
        url = `${url}/${pageName}/${blog?.title?.trim().replace(/\s+/g, "-")}-${
          blog?._id
        }`;
        break;
      case "add":
        url = `${url}/add`;
        break;
      case "draft":
        url = `${url}/draft`;
        break;
      case "preview":
        url = `${url}/preview/${blog?.title?.trim().replace(/\s+/g, "-")}-${
          blog?._id
        }`;
    }

    navigate(url);
  };

  useEffect(() => {
    getBlogs(Number(currentPage), pageLimit);
  }, [currentPage, selectedFilter, debounceValue]);

  const getBlogs = async (page, limit) => {
    setLoading(true);

    try {
      const response = await apiCall({
        url: apiUrl,
        method: "GET",
        params: {
          page,
          limit: limit || pageLimit,
          ...(selectedFilter === "ALL"
            ? {}
            : { isPublish: selectedFilter === "PUBLISHED" }),
          searchValue: debounceValue,
          searchFields: "title,content,author.firstName,author.lastName",
        },
      });

      setBlogs(response?.data);
      setPaginationData(response?.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filters = [
    { label: "All", value: "ALL" },
    { label: "Published", value: "PUBLISHED", params: { isPublish: true } },
    {
      label: "Unpublished",
      value: "UNPUBLISHED",
      params: { isPublish: false },
    },
  ];

  const handleDeleteBlog = async () => {
    setLoading(true);
    try {
      const response = await apiCall({
        url: `blogs/${blogIdToDelete}`,
        method: "DELETE",
      });

      toast.success("Deleted Successfully.");
      setDeleteModal(false);
      getBlogs();
    } catch (err) {
      setLoading(false);
      console.log("Err : ", err);
    }
  };

  const handleCloseDeleteModal = (blogId) => {
    setDeleteModal(!deleteModal);
    setBlogIdToDelete(blogId);
  };

  const handleToggle = async (blogId) => {
    setLoading(true);

    const config = {
      url: `blogs/${blogId._id}`,
      method: "PATCH",
      data: { statusUpdate: true, isPublish: !blogId.isPublish },
    };

    try {
      const response = await apiCall(config);
      getBlogs();
      toast.success(response.message);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    setSearchParams((prev) => {
      prev.set("currentPage", Number(event.selected) + 1);
      return prev;
    });
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: HEAD_BG_COLOR,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    TBL_ROW_THEME: {
      backgroundColor: theme.palette.action.hover,
    },
    STYLE_TBL_ROW_THEME: {
      border: 0,
    },
  }));

  return (
    <>
      {loading && <Loader />}
      <div className="min-height-dashboard px-5">
        <div className="main-content-gap">
          <div className="container-fluide">
            <h2 className="text-center topHeading ">{pageTitle}</h2>
            <div className="justify-content-end pb-3 gap-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                <div className="rec-radio">
                  <FilterButtonGroup
                    filters={filters}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </div>

                <div className="d-flex align-items-center gap-3 flex-wrap">
                  <div className="flex-grow-1">
                    <input
                      disabled={loading}
                      value={search}
                      type="search"
                      onChange={handleSearchInputChange}
                      placeholder="Search"
                      className="form-control p-2 w-100"
                      style={{ height: "40px" }}
                    />
                  </div>

                  <Button
                    onClick={() => handleNavigation({ pageName: "add" })}
                    variant="contained"
                    sx={{ minWidth: "40px", height: "40px" }}
                  >
                    <Tooltip title="Add Blog">
                      <span>Add Blog</span>
                    </Tooltip>
                  </Button>
                </div>
              </div>
            </div>
            <div className="cstm-table">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell align="left">Image</StyledTableCell>
                      <StyledTableCell align="left">Title</StyledTableCell>
                      <StyledTableCell align="left">Content</StyledTableCell>
                      {showPublishStatus && (
                        <StyledTableCell align="left">Status</StyledTableCell>
                      )}
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {blogs?.map((blog, index) => (
                      <StyledTableRow key={index + "v125"}>
                        <StyledTableCell component="th" scope="row">
                          {(paginationData.currentPage - 1) * pageLimit +
                            (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <img
                            src={`${BASEURL}/${blog.coverImage}`}
                            style={{ width: "70px" }}
                            alt=""
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <div className="title-ellipses-blog">
                            {blog.title}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <div className="content-ellipses-blog">
                            {blog.snippet}
                          </div>
                        </StyledTableCell>

                        {showPublishStatus && (
                          <StyledTableCell align="left">
                            <FormControlLabel
                              control={<Switch checked={blog.isPublish} />}
                              label={
                                blog.isPublish ? "Published" : "Unpublished"
                              }
                              onChange={() => handleToggle(blog)}
                            />
                          </StyledTableCell>
                        )}

                        <StyledTableCell align="center">
                          <div className="d-flex justify-content-center gap-2">
                            <Button
                              onClick={() =>
                                handleNavigation({
                                  blog,
                                  pageName: "preview",
                                })
                              }
                              variant="contained"
                            >
                              <Tooltip title="Preview Blog">
                                <RemoveRedEyeIcon />
                              </Tooltip>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ minWidth: "40px" }}
                              onClick={() => handleCloseDeleteModal(blog._id)}
                            >
                              <Tooltip title="Delete Blog">
                                <DeleteIcon />
                              </Tooltip>
                            </Button>
                            <Button
                              onClick={() =>
                                handleNavigation({
                                  blog,
                                  pageName: "edit",
                                })
                              }
                              variant="contained"
                              sx={{ minWidth: "40px" }}
                            >
                              <Tooltip title="Edit Blog">
                                <ModeEditIcon />
                              </Tooltip>
                            </Button>
                            {showPublishBtn && (
                              <Button
                                variant="contained"
                                sx={{ minWidth: "40px" }}
                              >
                                Publish
                              </Button>
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="pagin pagination-blog mb-3">
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={
              isNaN(paginationData.totalPages) ? 1 : paginationData.totalPages
            }
            forcePage={Number(paginationData.currentPage - 1)}
            renderOnZeroPageCount={null}
            previousLabel="< previous"
            nextLabel="next >"
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>

      <BaseModal
        classname="base_modal_mui"
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        maxWidth={600}
      >
        <DeleteModal
          handleSubmit={handleDeleteBlog}
          handleCloseModal={handleCloseDeleteModal}
          modalName={"Blog"}
        />
      </BaseModal>
    </>
  );
};

export default BlogList;
