import { Navigate } from "react-router-dom";
import React from "react";
import AdminLayout from "../Components/Admin/AdminLayout";

const PrivateRoutes = () => {
  const token = JSON.parse(localStorage.getItem("LogUser"));
  const role = localStorage.getItem("role");

  if (role === "false") {
    return <Navigate to={"/"} />;
  }

  return token ? <AdminLayout /> : <Navigate to={"/"} />;
};

export default PrivateRoutes;
