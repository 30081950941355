import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel } from "react-bootstrap";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Editor from "../Editor/Editor";
import "./blogform.css";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall } from "../../../../API/apiCall";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";
import ImageUploader from "../../../Imageuploader/ImageUploader";
import { BASEURL } from "../../../../constant/constant";
import { blogSchema } from "../../../../schema/schema";

const BlogForm = ({ pageTitle }) => {
  const { name } = useParams();
  const [blog, setBlog] = useState({
    title: "",
    author: { firstName: "", lastName: "" },
  });
  const [content, setContent] = useState("");
  const [contentData, setContentData] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [initialContentData, setInitialContentData] = useState("");
  const [initialBlogData, setInitialBlogData] = useState({
    title: "",
    author: { firstName: "", lastName: "" },
  });

  const [loading, setLoading] = useState(false);

  const [fileMapping, setFileMapping] = useState([]);
  const [fileIndexURL, setFileIndexURL] = useState([]);
  const [formError, setFormError] = useState({});

  const id = name?.split("-")?.pop();

  const navigate = useNavigate();

  // To navigate on blog details page
  const handleNavigation = () => {
    navigate(`/admin/blogs/preview/${name}`);
  };

  const handleInputChange = (field, value, option) => {
    if (option === "deleteImage") {
      if (field === "coverImage") {
        delete blog.coverImage;
        if (pageTitle !== "Add Blog")
          blog["coverImage"] = initialBlogData.coverImage;
      } else if (field === "authorImage") {
        delete blog.authorImage;
        blog.author["profile"] = initialBlogData?.author?.profile;
      }
      return setBlog(() => ({ ...blog }));
    }

    if (field === "authorImage" || field === "coverImage") {
      setBlog((prevBlog) => ({
        ...prevBlog,
        [field]: value,
      }));
    } else if (field === "author_firstName" || field === "author_lastName") {
      setBlog((prevBlog) => ({
        ...prevBlog,
        author: {
          ...prevBlog.author,
          [field.split("_")[1]]: value,
        },
      }));
    } else {
      setBlog((prevBlog) => ({
        ...prevBlog,
        [field]: value,
      }));
    }
  };

  const handleContentChange = useCallback((newContent) => {
    const { name, value } = newContent;

    setContentData(newContent);
  }, []);

  useEffect(() => {
    setIsChanged(
      JSON.stringify(blog) !== JSON.stringify(initialBlogData) ||
        JSON.stringify(contentData) !== JSON.stringify(initialContentData)
    );
  }, [blog, contentData]);

  useEffect(() => {
    if (pageTitle === "Edit Blog" && id) {
      setLoading(true);
      getSingleBlog();
    }
  }, []);

  const getSingleBlog = async () => {
    try {
      const response = await apiCall({
        url: `blogs/${id}`,
        method: "GET",
        params: {
          userType: "admin",
        },
      });

      setBlog(response?.data?.data);
      setContent(response?.data?.data?.content);
      setContentData(response?.data?.data?.content);

      setInitialContentData(response?.data?.data?.content);
      setInitialBlogData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getChangedFields = (originalData, updatedData) => {
    const changedFields = {};

    Object.keys(updatedData).forEach((key) => {
      if (originalData[key] !== updatedData[key]) {
        changedFields[key] = updatedData[key];
      }
    });

    return changedFields;
  };

  const getDeletedImagesUrls = (
    baseUrl,
    initialContentDataUrls,
    updatedContentData
  ) => {
    // Create a regex to extract paths from the base URL
    const regex = new RegExp(`${baseUrl}(\\/[^\\s"']+)`, "g");

    // Extract paths from the content
    const extractedUrls = new Set(
      [...initialContentDataUrls.matchAll(regex)].map((match) => match[1])
    );

    // Find and return the extracted URLs and missing URLs
    return [...extractedUrls].filter(
      (url) => !updatedContentData.includes(`${baseUrl}${url}`)
    );
  };

  const handleSaveAndPublish = async (event) => {
    const error = blogSchema({
      title: blog.title,
      authorFirstName: blog.author?.firstName,
      authorLastName: blog.author?.lastName,
      contentData: contentData === "<p><br></p>" ? "" : contentData,
    });

    console.log("Error : ", error);

    if (Object.keys(error).length !== 0) {
      setFormError(error);
      return;
    }

    setLoading(true);
    try {
      const imagesUrlsToDelete = [];
      const formData = new FormData();
      const baseUrl = BASEURL;

      //  Code for edit blog.
      if (pageTitle === "Edit Blog") {
        const imagesToDelete = getDeletedImagesUrls(
          baseUrl,
          initialContentData,
          contentData
        );

        if (imagesToDelete.length) {
          imagesUrlsToDelete.push(...imagesToDelete);
        }

        const updatedData = getChangedFields(initialBlogData, blog);

        // updatedData["imageBaseUrl"] = "http://localhost:6770";

        // Add updated content data in updatedData object for sending in api payload.
        if (
          JSON.stringify(contentData) !== JSON.stringify(initialContentData)
        ) {
          updatedData["content"] = contentData;
        }

        // Add old url of authorImage to delete if user updated authorImage.
        if (
          blog.authorImage &&
          blog.author?.profile &&
          blog.author?.profile !==
            "uploads/blogs/authorImages/profile-image.png"
        ) {
          imagesUrlsToDelete.push(initialBlogData.author?.profile);
        }

        // Add old url of coverImage to delete if user updated coverImage.
        if (
          typeof blog?.coverImage !== "string" &&
          initialBlogData.coverImage !==
            "uploads/blogs/contentImages/default-blog.png"
        ) {
          imagesUrlsToDelete.push(initialBlogData.coverImage);
        }

        if (imagesUrlsToDelete.length) {
          updatedData["imagesUrlsToDelete"] = imagesUrlsToDelete;
        }

        // Append updated data in formData.
        for (const key in updatedData) {
          const value = updatedData[key];
          if (typeof value === "object" && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }

        // Filter both arrays together
        for (let i = fileIndexURL.length - 1; i >= 0; i--) {
          if (!contentData.includes(fileIndexURL[i])) {
            fileIndexURL.splice(i, 1);
            fileMapping.splice(i, 1);
          }
        }

        // For each image and URL
        fileMapping.forEach((file, index) => {
          formData.append("fileMapping", file);
          formData.append("fileUrls", fileIndexURL[index]);
        });

        const response = await apiCall({
          url: `blogs/${id}`,
          method: "PATCH",
          data: formData,
        });

        setBlog(response?.data?.data);
        setInitialBlogData(response?.data?.data);
        setInitialContentData(response?.data?.data?.content);

        setFormError({});
        toast.success(response.message);
        setLoading(false);
      } else {
        let isPublished = false;

        if (event.target?.name === "Save&PublishBtn") {
          isPublished = true;
        }

        // Code for add blog.

        // console.log("Content Data : -0-00-0 : ", contentData);
        // if (!contentData.trim() || contentData === "<p><br></p>") {
        //   setLoading(false);
        //   return toast.error("Content is required!");
        // }

        const payloadBody = {
          ...blog,
          isPublish: isPublished,
          content: contentData.replace(
            /<img([^>]*?)style="float:\s*right\s*;"([^>]*?)>/g,
            "<img$1 class='image-right'$2>"
          ),
          imageBaseUrl: baseUrl,
        };

        for (const key in payloadBody) {
          const value = payloadBody[key];
          if (typeof value === "object" && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }

        // Filter both arrays together
        for (let i = fileIndexURL.length - 1; i >= 0; i--) {
          if (!contentData.includes(fileIndexURL[i])) {
            fileIndexURL.splice(i, 1);
            fileMapping.splice(i, 1);
          }
        }

        // For each image and URL
        fileMapping.forEach((file, index) => {
          formData.append("fileMapping", file);
          formData.append("fileUrls", fileIndexURL[index]);
        });

        const response = await apiCall({
          url: "blogs",
          method: "POST",
          data: formData,
        });

        toast.success(response.message);
        setLoading(false);
        navigate("/admin/blogs");
      }
    } catch (error) {
      console.error("Error saving blog:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <h2 className="heading_dashboard">{pageTitle}</h2>
      <div className="main-blog-detail-page">
        <div className="modal-header"></div>
        <div className="modal-content blog-modal-content">
          <Row className="g-2">
            <Col md={12}>
              <FloatingLabel controlId="floatingInputGrid" label="Blog Title">
                <Form.Control
                  type="text"
                  value={blog.title || ""}
                  name="title"
                  onChange={(e) => handleInputChange("title", e.target.value)}
                  isInvalid={formError?.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formError?.title}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Author First Name"
                  >
                    <Form.Control
                      type="text"
                      value={blog?.author?.firstName || ""}
                      name="author_firstName"
                      onChange={(e) =>
                        handleInputChange("author_firstName", e.target.value)
                      }
                      isInvalid={formError?.authorFirstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.authorFirstName}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Author Last Name"
                  >
                    <Form.Control
                      type="text"
                      value={blog?.author?.lastName || ""}
                      name="author_lastName"
                      onChange={(e) =>
                        handleInputChange("author_lastName", e.target.value)
                      }
                      isInvalid={formError?.authorLastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.authorLastName}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="pt-2">
              <span>Author Profile Image : </span>
              <br />
              <small className="text-danger">
                Note : Image size should be less then 5 MB(jpeg/jpg/png/gif).
              </small>
              <ImageUploader
                onFileChange={handleInputChange}
                name="authorImage"
                value={blog?.author?.profile || null}
                imgStyle={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  display: "block",
                  border: "2px solid green",
                }}
              />
            </Col>
            <Col md={12} className="pt-2">
              <span>Blog Cover Image : </span>
              <br />
              <small className="text-danger">
                Note : Image size should be less then 5 MB(jpeg/jpg/png/gif).
              </small>
              <ImageUploader
                onFileChange={handleInputChange}
                name="coverImage"
                value={blog?.coverImage || null}
                imgStyle={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  display: "block",
                  border: "2px solid green",
                }}
              />
            </Col>
            <Col md={12} className="pb-2">
              <Editor
                content={content}
                handleInputChange={handleContentChange}
                fileMapping={fileMapping}
                setFileMapping={setFileMapping}
                fileIndexURL={fileIndexURL}
                setFileIndexURL={setFileIndexURL}
                isInvalid={formError?.title}
              />
              {formError?.contentData && (
                <span style={{ color: "red" }}>{formError?.contentData}</span>
              )}
            </Col>
          </Row>
        </div>
        <div className="modal-footer my-4 d-flex justify-content-end gap-2">
          {pageTitle !== "Edit Blog" && (
            <Button
              className="save-btn save-btn-dashboard save-draft-button"
              variant="primary"
              name="SaveBtn"
              onClick={handleSaveAndPublish}
              // disabled={!isChanged}
            >
              Save
            </Button>
          )}
          {pageTitle !== "Add Blog" && (
            <Button
              className="save-btn save-btn-dashboard preview-btn-blog d-flex gap-2"
              variant="primary"
              onClick={handleNavigation}
            >
              <RemoveRedEyeIcon />
              <span>Preview</span>
            </Button>
          )}
          <Button
            onClick={handleSaveAndPublish}
            className="save-btn save-btn-dashboard"
            variant="primary"
            name="Save&PublishBtn"
            disabled={pageTitle === "Add Blog" ? false : loading || !isChanged}
          >
            {pageTitle === "Add Blog"
              ? "Save & Publish Changes"
              : "Save Changes"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(BlogForm);
