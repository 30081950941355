import { Schema, validateForm } from "./schemaClass";

export const blogSchema = validateForm({
  title: new Schema().required().minLength(10).maxLength(200),
  authorFirstName: new Schema().maxLength(30),
  authorLastName: new Schema().maxLength(30),
  contentData: new Schema().required(),
});

export const socialLinkSchema = validateForm({
  name: new Schema().required().onlyString().maxLength(30),
  url: new Schema().required().url(),
  icon: new Schema().required(),
});

export const FAQSchema = validateForm({
  question: new Schema().required(),
  answer: new Schema().required(),
});
