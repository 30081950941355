import React from "react";
import bannerImage from "../../../../assets/images/banner-img.png";
import "../Banner/banner.css";
import { BASEURL } from "../../../../constant/constant";
import dayjs from "dayjs";
import { Row } from "react-bootstrap";

export const Banner = ({ blogs }) => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide student-banner"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-indicators">
          {blogs.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {blogs.map((blog, index) => (
            <div
              key={blog._id}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <div className="container h-100">
                <div className="row caroselRow h-100">
                  <div className="col-md-6 d-flex justify-content-center align-items-center flex-column">
                    {/* <button>Reading</button> */}
                    <div className="bannerContentTop">
                      <h5 className="blog-heading">{blog.title}</h5>
                      <div className="blog-text-content">{blog.snippet}</div>
                    </div>
                    <div className="bannerContentBottom">
                      <div className="student-profile">
                        <img src={`${BASEURL}/${blog.author?.profile}`} />
                        <div>
                          <h6>
                            {blog.author?.firstName || blog.author?.lastName
                              ? `${blog.author.firstName} ${blog.author.lastName}`
                              : blog.author?.lastName
                              ? "Anonymous"
                              : blog.author?.firstName
                              ? blog.author.firstName
                              : "Anonymous"}
                          </h6>
                          <p>
                            {dayjs(blog.createdAt).format(
                              "DD MMM YYYY . hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 d-none d-md-block ">
                    <div className="bannerImageParent">
                      <img src={bannerImage} className="bannerImage"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="nextpre-btn">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Banner;
