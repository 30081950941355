import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Navbar from "../../../Navbar";
import Footer from "../../../Footer";
import Loader from "../../../loader/Loader";

import "./BlogDetails.css";

import dayjs from "dayjs";
import { BASEURL } from "../../../../constant/constant";
import { apiCall } from "../../../../API/apiCall";

const MemberTyp = localStorage.getItem("MemberType");

const BlogDetails = () => {
  const [isLoader, setisLoader] = useState(false);
  const [blog, setBlog] = useState([]);

  const navigate = useNavigate();

  const { name } = useParams();
  const parts = name.split("-");
  const id = parts.pop();

  useEffect(() => {
    setisLoader(true);
    const getSingleBlog = async () => {
      try {
        const response = await apiCall({
          url: `/blogs/${id}`,
          method: "GET",
        });
        console.log("TTTTTTTTTTTTTTTTTTTTTT");
        setBlog(response?.data?.data);
        setisLoader(false);
      } catch (error) {
        // toast.error(error?.response?.data?.message || error.message);
        navigate("/blogs");
        setisLoader(false);
      }
    };
    getSingleBlog();
  }, []);

  const replaceImagePlaceholders = (content, contentImages) => {
    // Replace placeholders like {imageIndex} with actual image paths
    return content?.replace(/\{image(\d+)\}/g, (_, index) => {
      const imageUrl = contentImages[parseInt(index, 10)];
      return imageUrl
        ? `<img class="blog-content-image" src="${`${BASEURL}/${imageUrl}`}" alt="Blog Image" style="max-width: 100%; height: auto;" />`
        : "";
    });
  };

  const backToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      {!isLoader && (
        <div className="container py-4">
          <div className="detailprofile-outer">
            <div className="blog-title">
              <span className="backToHome" onClick={backToPreviousPage}>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#cccccc"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 10L3.64645 10.3536L3.29289 10L3.64645 9.64645L4 10ZM20.5 18C20.5 18.2761 20.2761 18.5 20 18.5C19.7239 18.5 19.5 18.2761 19.5 18L20.5 18ZM8.64645 15.3536L3.64645 10.3536L4.35355 9.64645L9.35355 14.6464L8.64645 15.3536ZM3.64645 9.64645L8.64645 4.64645L9.35355 5.35355L4.35355 10.3536L3.64645 9.64645ZM4 9.5L14 9.5L14 10.5L4 10.5L4 9.5ZM20.5 16L20.5 18L19.5 18L19.5 16L20.5 16ZM14 9.5C17.5898 9.5 20.5 12.4101 20.5 16L19.5 16C19.5 12.9624 17.0376 10.5 14 10.5L14 9.5Z"
                      fill="#222222"
                    ></path>{" "}
                  </g>
                </svg>
              </span>
              <h1>{blog.title}</h1>
            </div>
            <div className="details-profile">
              <img src={`${BASEURL}/${blog.author?.profile}`} />
              <div>
                <h6>
                  {blog.author?.firstName && blog.author?.lastName
                    ? `${blog.author.firstName} ${blog.author.lastName}`
                    : blog.author?.lastName
                    ? "Anonymous"
                    : blog.author?.firstName
                    ? blog.author.firstName
                    : "Anonymous"}
                </h6>
                <p> {dayjs(blog.createdAt).format("DD MMM YYYY . hh:mm A")}</p>
              </div>
            </div>
            <div className="blog-image">
              <img
                src={`${BASEURL}/${blog.coverImage}`}
                alt="cover-image.jpg"
              />
            </div>
            <div className="blog-content">
              {/* <div dangerouslySetInnerHTML={{ __html: blog.content }} /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceImagePlaceholders(
                    blog.content,
                    blog.contentImages
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {/* <div>
        <BlogEditor></BlogEditor>
        <h1>Hello Ali</h1>
      </div> */}
      {!isLoader && <Footer />}
    </>
  );
};

export default BlogDetails;
