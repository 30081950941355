import React, { useRef, memo } from "react";
import "jodit";
import JoditEditor from "jodit-react";
import "./editor.css";
import { toast } from "react-toastify";

function Editor({
  content,
  handleInputChange,
  fileMapping,
  setFileMapping,
  fileIndexURL,
  setFileIndexURL,
}) {
  console.log("Editer Rebdered");
  const editorRef = useRef(null);

  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "superscript",
    "subscript",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "link",
    "table",
    "|",
    "hr",
    "eraser",
    "|",
    {
      name: "image",
      exec: function (editor) {
        console.log("IIIIIIIIIIIIIIIII");
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e) => {
          const file = e.target.files[0];
          if (!file) return;

          // Validate file type
          if (!file.type.startsWith("image/")) {
            toast.error("Please select a valid image file.");
            return;
          }

          const tempURL = window.URL.createObjectURL(file);
          editor.selection.insertImage(tempURL);

          setFileMapping([...fileMapping, file]);
          setFileIndexURL([...fileIndexURL, tempURL]);

          const updatedContent = editor.getEditorValue();
          handleInputChange(updatedContent);
        };
        input.click();
      },
    },
  ];

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePaste: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
    },
    width: "100%",
    height: 800,
  };

  const handleContentChange = (value) => {
    console.log("Value : ", value);
    handleInputChange(value);
  };

  return (
    <>
      <div style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
        <JoditEditor
          value={content}
          ref={editorRef}
          config={editorConfig}
          onChange={handleContentChange}
        />
      </div>
    </>
  );
}

export default memo(Editor);
