import React, { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const FilterButtonGroup = ({ filters, selectedFilter, setSelectedFilter }) => {
  const [, setSearchParams] = useSearchParams();

  const handleFilter = (filter) => {
    setSelectedFilter(filter.value);
    setSearchParams((prev) => {
      prev.set("currentPage", 1);
      return prev;
    });
  };

  return (
    <>
      <div className=" cstmTabbuttonGroup">
        {filters.map((filter) => (
          <Button
            key={filter.value}
            onClick={() => handleFilter(filter)}
            disabled={selectedFilter === filter.value}
            className={`me-2 ${
              selectedFilter === filter.value ? "active" : ""
            }`}
          >
            {filter.label}
          </Button>
        ))}
      </div>
    </>
  );
};

export default FilterButtonGroup;
